import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";

const Brands = () => {

  const brands = useStaticQuery(graphql`
    query Brands {
      images: allFile(
        filter: {relativeDirectory: {eq: "brands"}, extension: {in: ["jpg", "webp", "jpeg", "png"]}}
      ) {
        edges {
          node {
            name
            id
            publicURL
            childImageSharp {
              gatsbyImageData(width: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      svg: allFile(
        filter: {relativeDirectory: {eq: "brands"}, extension: {in: ["svg"]}}
      ) {
        edges {
          node {
            name
            id
            publicURL
          }
        }
      }
    }
  `);

  return (
    <aside className="w-full flex flex-col items-start max-w-prose bg-gray-100 p-8">
      <strong className="block mb-4">
        Dirbame su žinomais prekių ženklais
      </strong>
      <ul className="w-full grid grid-cols-3">
        {brands.svg.edges.map(({ node }) => (
          <li key={node.id} className="flex items-center border bg-gray-50 justify-center py-6 select-none">
            <div className="flex items-center justify-center w-28 h-8">
              <img className="block h-full w-auto" src={node.publicURL} alt={node.name} />
            </div>
          </li>
        ))}
        {brands.images.edges.map(({ node }) => (
          <li key={node.id} className="flex items-center border bg-gray-50 justify-center py-6 select-none">
            <div className="flex items-center justify-center w-28 h-8">
              <GatsbyImage className="block h-full w-auto" image={node.childImageSharp.gatsbyImageData} alt={node.name} />
            </div>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default Brands;