import * as React from "react";
import { graphql } from "gatsby";
import { Page } from "~/components/ui";
import Brands from "~/components/brands";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export default function Homepage({ data }) {
  const meta = {
    title: "Vėdinimas, oro kondicionavimas ir šildymas",
    description: "Projektuojame ir montuojame klientų individualius poreikius atitinkančius vėdinimo, kondicionavimo sistemas tiek ir privatiems klientams tiek verslo klientams."
  };

  return (
    <Page {...meta}>
      <div className="prose mb-6">
        <p>
          Projektuojame ir montuojame klientų individualius poreikius atitinkančius
          vėdinimo, kondicionavimo sistemas tiek ir privatiems klientams tiek verslo klientams.
        </p>
        <p>
          Oro kondicionavimas leidžia kontroliuoti patalpų oro temperatūrą, drėgmę, švarumą ir pasiskirstymą, mažina neigiamą poveikį sveikatai. Mūsų siūlomi oro kondicionieriai tiekia švarų ir sveiką orą, sukuria komfortišką aplinką ir užtikrina optimalią patalpų temperatūrą.
        </p>

        <h2 className="leading-tight">
          Teikiamos paslaugos:
        </h2>

        <div className="grid grid-cols-1 gap-2">
          <details className="group bg-blue-50 p-3" id="details-tab-service-0" open>
            <summary className="cursor-pointer flex items-center relative">
              <span className="p-1">
                <ChevronDownIcon className="h-5 w-5 -ml-2 group-open:rotate-180" />
              </span>
              <strong className="leading-tight px-1">
                Vėdinimo ir oro kondicionavimo sistemų montavimas ir priežiūra
              </strong>
            </summary>
            <div className="px-6" id="details-tab-service-0-content">
              <p className="text-sm">
                Montuojame rekuperatorius, centrinius, autonominius kondicionierius, vandens šaldymo mašinas ir buitinius kondicionierius skirtus gamybinėms, pramoninėms, prekybinėms, gyvenamosioms patalpoms ir ypatingos paskirties objektams, gyvenamosioms patalpoms, kotedžams, ofisams ir t.t.;
              </p>
              <p className="text-xs font-mono bg-gray-50 text-gray-500 p-4 -mx-4">
                Vėdinimo sistemos turi būti įrengtos pagal Statybų techninių reglamentų reikalavimus. Pagal juos, kvalifikuoti įmonės darbuotojai parengs tinkamą vėdinimo sistemos projektą.
              </p>
            </div>
          </details>
          <details className="group bg-blue-50 p-3" id="details-tab-service-0">
            <summary className="cursor-pointer flex items-center relative">
              <span className="p-1">
                <ChevronDownIcon className="h-5 w-5 -ml-2 group-open:rotate-180" />
              </span>
              <strong className="leading-tight px-1">
                Rekuperatorių montavimas ir priežiūra
              </strong>
            </summary>
            <div className="px-6" id="details-tab-service-0-content">
              <p className="text-sm">
                Kiekvieniems namams yra svarbus tinkamas vėdinimas. Rekuperatoriai – pagrindiniai prietaisai, kurie yra skirti užtikrinti tinkamą vėdinimą ir šilumos išlaikymą.
              </p>
              <ul className="text-sm">
                <li>Rekuperatorių filtrų keitimas;</li>
              </ul>
              <p className="text-xs font-mono bg-gray-50 text-gray-500 p-4 -mx-4">
                Vėdinimo sistemos turi būti įrengtos pagal Statybų techninių reglamentų reikalavimus. Pagal juos, kvalifikuoti įmonės darbuotojai parengs tinkamą vėdinimo sistemos projektą.
              </p>
            </div>
          </details>
          <details className="bg-blue-50 p-4" id="details-tab-service-0">
            <summary className="cursor-pointer flex items-center relative">
              <span className="p-1">
                <ChevronDownIcon className="h-5 w-5 -ml-2 group-open:rotate-180" />
              </span>
              <strong className="leading-tight px-1">
                Šildymo sistemų montavimas ir priežiūra
              </strong>
            </summary>
            <div className="px-6">
              <p className="text-sm">
                Teikiame konsultavimo, šildymo sistemų projektavimo, montavimo bei jų aptarnavimo paslaugas. Esant poreikiui, padėsime išsirinkti, įsigyti ir įrengsime Jums tinkamiausią šilumos šaltinį (šilumos siurblį). Šildymas šilumos siurbliais yra sparčiai populiarėjantis šildymo būdas, kai patalpų komfortinei temperatūrai palaikyti naudojama grunto, vandens telkinio ar lauko oro temperatūra.
              </p>
            </div>
          </details>
        </div>
      </div>
      <Brands />
    </Page>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    image: file(name: {eq: "head"}) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;